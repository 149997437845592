@app.controller 'PagesController', (PageService, ClassesService, $stateParams, $state, AnalyticsService, MetadataService, ExperimentService, ModalService, $filter, $rootScope, navService, $scope, $location, $http, $timeout, authenticationService, utilities, localStorageService) ->

  handleSuccess = (data) =>
    # For promotions with landing pages instead of redirects
    # /promo will be reloaded with the discount_id parameter /promo?discount_id=DISCOUNTID

    # if _.isNull(_.get($state.currentUser, 'opt_in')) && _.isEmpty(localStorageService.get('openStudioPassSubscription'))
      # social media sign up users after sign-up consent page needs to shown up
      # if user not showed up the consent then consent modal will show
      # ModalService.open('modalConsent')

    if $state.showModal
      ModalService.open('login', source: 'nav')
    discountIdFromURL = $location.search().discount_id
    $state.userCount = data.userCount
    if data.isPromotion && data.discountId && !discountIdFromURL
      $location.search('discount_id', data.discountId)
      # Timeout to prevent a redirect loop in Safari.
      $timeout(->
        location.reload(true)
      ,1)
      return

    @content = data
    firstComponent = @content.components[0]
    @skipToContentId = _.get(@content.components[1], 'id',
                             _.get(@content.components[0], 'id', '#'))
    @firstHeroComponent = _.find(@content.components, (comp) -> comp.componentType == 'hero') || {}
    firstComponentType = firstComponent.componentType
    firstComponentNav = firstComponent.meta.nav
    @scheduleDates = _.get(@content, 'scheduleDates', [])
    if ((firstComponentType == 'hero' && firstComponent.meta.items[0]?.content.heroType != 'collection') || firstComponentNav == 'ghost')
      navService.setState('nav-ghost')
    if $state.currentUser && (firstComponentType == 'banner' && firstComponent.meta.items[0]?.content.type == 'Madlib') && $state.current.views.main.data?.pageId != 'gallery'
      navService.setState('nav-ghost')

    # TODO Allow the page editor to set SEO description and keywords for a page
    title = $filter('titlecase')(@content.title)
    description = @content.shortDescription
    keywords = @content.title
    image = @content.featuredImageUrl || @content.components[0].meta.items[0].content.image
    canonical = $state.href($state.current.name)
    MetadataService.set
      title: title
      description: description
      keywords: keywords
      canonical: canonical
      # Hacky, but no disaster if it is wrong
      sousVideTargeted: _.contains(title.toLowerCase(), 'sous vide')

      og:
        title: "#{title} | ChefSteps"
        image: image
        url: canonical
        description: description
      twitter:
        title: "#{title} | ChefSteps"
        image: image

    # Set exportUrl
    exportContent = {}
    angular.copy @content, exportContent
    # Don't export id or shortDescription
    delete exportContent.id
    delete exportContent.shortDescription

    data = JSON.stringify(@content)
    blob = new Blob([ data ], { type : 'application/json' })
    @exportUrl = (window.URL || window.webkitURL).createObjectURL( blob )
    @exportTitle = "#{@content.title}_#{Date.now()}.json"

    hasPaywallComp = (_.find _this.content.components, (c) ->  c.meta.showTo == "non-purchased-&-non-subscribed")?
    @pageRequiresNonPurchaseAndNonSub = hasPaywallComp && !$state.currentUser?.premium && !(_.includes(_.get($state?.currentUser, 'purchased_classes', []), @slug))

  handleError = (error) ->
    console.log 'Error getting page: ', error

  @handleSuccess = handleSuccess
  @handleError = handleError

  pageId = $state.current.views.main.data?.pageId || $stateParams.id || "joule-#{$stateParams.slug}"
  pageId = 'new-premium-lp' if pageId == 'premium' && $state.currentUser?.premium
  pageId = pageId.toLowerCase()
  @slug = pageId
  @classDetail = {}

  # CHEFWEB-426, help out bad search engines
  $state.go 'main.home' if pageId == "!"

  userToken = localStorage.getItem('access_token')
  headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
  country_code = $location.search().country_code
  schedule_date = $location.search().schedule_date
  if !_.isEmpty(country_code)
    $http(
      method: 'GET'
      headers: headerParams
      url: "#{csConfig.chefstepsEndpoint}/api/v0/pages/#{pageId}?country_code=#{country_code}&schedule_date=#{schedule_date}"
    ).then (response) =>
      @handleSuccess(response.data)
    .catch (error) =>
      @handleError(error)
  else
    PageService.show {id: pageId}, handleSuccess, handleError

  if $state.current.views?.data?.classes == true
    @source = 'class'
    ClassesService.findBySlug(@slug).then (res) =>
      @classDetail = res.data

  @moveUp = (index) =>
    if index > 0
      # Remove the item at index
      removed = @content.components[index]
      @content.components.splice(index, 1)

      # Insert item
      @content.components.splice(index-1, 0, removed)
      setPositions()

  @moveDown = (index) ->
    numComponents = @content.components.length
    if index < (numComponents - 1)
      # Remove the item at index
      removed = @content.components[index]
      @content.components.splice(index, 1)

      # Insert item
      @content.components.splice(index + 1, 0, removed)
      setPositions()

  setPositions = =>
    angular.forEach @content.components, (component, index) ->
      component.position = index

  @save = =>
    # Dont save shortDescription until we fix the api to accept shortDescription mass assignment
    delete @content.shortDescription
    delete @content.isPromotion
    delete @content.redirectPath
    delete @content.discountId
    handleUpdateSuccess = (data) =>
      console.log 'saved data: ', data
      @content = data
      @editing = false

    handleUpdateError = ->
      console.log 'Error getting page.'

    PageService.update {id: $stateParams.id, page: @content}, handleUpdateSuccess, handleUpdateError

  @cancel = =>
    @editing = false
    $state.go $state.current, {}, reload: true

  @showPreview = ->
    ModalService.open('pagePreview', pageId: pageId, schedule_dates: @scheduleDates)

  @showComponent = (component) =>
    return true if @editing

    return false if component._destroy == '1'

    if !! component.meta?.experiment?.experimentName
      e = component.meta.experiment
      return false if ! ExperimentService.isInVariation(e.experimentName, e.variationName, e.variationCount)

    canShow = utilities.showCountryBasedComponent(@currentUser, component.applyToCountries, component.countries)
    return false if canShow == false

    canShow = utilities.showScheduledComponent(component.scheduleStartDate, component.scheduleEndDate)
    return false if canShow == false

    showCarousel = true
    if component.componentType == 'carousel'
      unless component.meta.items[0].content.itemType == 'simple carousel' && component.meta.items[0].content.collectionType == 'Smart'
        showCarousel = _.get(component.meta.items[0].content, 'activityGroup.activities', []).length > 0

    return false if showCarousel == false

    utilities.maybeShowComponent(@currentUser, component.meta.showTo, pageId)

  @currentUser = $state.currentUser

  $rootScope.$on '$stateChangeSuccess', =>
    @currentUser = $state.currentUser
    true

  $scope.$watch 'page.importFile', (newValue) =>
    if newValue
      # Mark the old page components to be removed
      oldComponents = @content.components.map (component) -> _.extend(component,{_destroy: '1'})

      newPageContent = newValue
      console.log 'newPageContent: ', newPageContent
      importComponents = newPageContent.components.map (component) -> _.extend(component,{componentParentId: pageId, id: null})

      # Set the page components
      # Dos not persist until admin presses save
      @content.components = _.union oldComponents, importComponents

  if $state.current.name == 'home'
    AnalyticsService.track 'Viewed New Homepage'

  $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
    if fromState.name == 'main.classes' && toState.name == 'main.activity' && ! toParams['context']
      # Add context param to class/collection link
      toParams['context'] = fromParams.id
      event.preventDefault()

      # Require sign-in/up - this will resolve right away if already signed in
      ModalService.open('signup',
        source: 'freeClass'
        extraText: "A Free Account Is Required To View This Class"
      ).then(
        -> $state.go 'main.activity', toParams
      )


  # Redirect old CS style hash URLs like:
  # https://www.chefsteps.com/classes/poutine#/finding-perfect-french-fry-potatoes to
  # https://www.chefsteps.com/activities/finding-perfect-french-fry-potatoes?context=poutine
  if $state.current.name == 'main.classes' && $location.hash()[0] == '/'
    slug = $location.hash()[1..]
    $state.go 'main.activity', {slug: slug, context: $state.params['id']}

  this
