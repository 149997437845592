@app.service 'subscriptionService', ($http, $q, $state, $timeout, localStorageService, AnalyticsService, GtagServiceHelper, ClassesService) ->

  @placeOrder = (planId, onError) =>
    AnalyticsService.track 'Order Checkout Loading', {planId: planId}
    @chargebeeInstance.openCheckout(
      hostedPage: ->
        userToken = localStorage.getItem('access_token')
        return $http(
          method: 'POST'
          url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/generate_checkout_url"
          data:
            plan_id: planId
          headers:
            Authorization: "Bearer #{userToken}"
        ).then (res) ->
          return res.data
      loaded: ->
        AnalyticsService.track 'Order Checkout Loaded', {planId: planId}
      step: (currentStep) ->
        AnalyticsService.track 'Order Checkout Step', {planId: planId, currentStep: currentStep}
      close: ->
        AnalyticsService.track 'Order Checkout Closed', {planId: planId}
      success: (hostedPageId) =>
        AnalyticsService.track 'Order Checkout Completed', {planId: planId, hostedPageId: hostedPageId}
        ClassesService.getPrice(planId).then (result) ->
          GtagServiceHelper.purchaseClass(_.get(result, 'data.price'))
        return this.syncOrders().then (_) ->
          $state.reload($state.current)
      error: (error) ->
        AnalyticsService.track 'Subscription Checkout Error', {planId: planId, error: error}
        if error.status == 400
          onError(error['data']['message'])
        else
          onError(error)
    )

  @subscribe = (planId, onError, isGift) =>
    if !isGift && @userHasSubscription(planId)
      onError('ALREADY_SUBSCRIBED')
      return

    AnalyticsService.track 'Subscription Checkout Loading', {planId: planId}
    @chargebeeInstance.openCheckout(
      hostedPage: ->
        userToken = localStorage.getItem('access_token')
        return $http(
          method: 'POST'
          url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/generate_checkout_url"
          data:
            plan_id: planId
            is_gift: isGift
          headers:
            Authorization: "Bearer #{userToken}"
        ).then (res) ->
          data = res.data
          if _.get(data, 'cs_hosted_page')
            csParams = _.get(data, 'cs_params', {})
            localStorageService.set('chargeBeeGreetMessage', csParams['reactivation'])
            data = _.omit(data, ['cs_params'])
            return _.get(data, 'cs_hosted_page')
          else
            return data
      loaded: ->
        AnalyticsService.track 'Subscription Checkout Loaded', {planId: planId}
      step: (currentStep) ->
        AnalyticsService.track 'Subscription Checkout Step', {planId: planId, currentStep: currentStep}
      close: ->
        AnalyticsService.track 'Subscription Checkout Closed', {planId: planId}
      success: (hostedPageId) =>
        if isGift then GtagServiceHelper.subscribeSPGift() else GtagServiceHelper.subscribeSP()
        AnalyticsService.track 'Subscription Checkout Completed', {planId: planId, hostedPageId: hostedPageId}
        return this.syncSubscriptions().then (_) ->
          $state.reload($state.current)
      error: (error) ->
        AnalyticsService.track 'Subscription Checkout Error', {planId: planId, error: error}
        if error.status == 400
          onError(error['data']['message'])
        else
          onError(error)
    )

  # TODO - figure out errors here
  # will do this when do user profile page with configure link
  # Chargebee does not have an error parameter for these portal APIs
  @configure = (onError) =>
    @chargebeeInstance.setPortalSession(->
      userToken = localStorage.getItem('access_token')
      return $http(
        method: 'POST'
        url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/create_portal_session"
        headers:
          Authorization: "Bearer #{userToken}"
      ).then (res) ->
        return res.data
    )
    chargebeePortal = @chargebeeInstance.createChargebeePortal()
    chargebeePortal.open(
      subscriptionReactivated: =>
        return this.syncSubscriptions().then (_) ->
          $state.reload($state.current)
      subscriptionCancelled: =>
        return this.syncSubscriptions().then (_) ->
          $state.reload($state.current)
      close: ->
    )

  @syncSubscriptions = ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/sync_subscriptions"
      headers:
        Authorization: "Bearer #{userToken}"
    ).then (res) ->
      return $state.currentUser = res.data

  @syncOrders = ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/purchase_orders/sync"
      headers:
        Authorization: "Bearer #{userToken}"
    ).then (res) ->
      return $state.currentUser = res.data

  @userHasSubscription = (planId) ->
    hasSubscription = false
    subscriptions = $state.currentUser?.subscriptions

    if subscriptions
      angular.forEach subscriptions, (item) ->
        if item.plan_id == planId && item.is_active
          hasSubscription = true
    
    hasSubscription

  @userHasCancelledSubscription = (planId) ->
    hasCancelledSubscription = false
    subscriptions = $state.currentUser?.subscriptions

    if subscriptions
      angular.forEach subscriptions, (item) ->
        if item.plan_id == planId && item.status == 'cancelled'
          hasCancelledSubscription = true
    hasCancelledSubscription

  @getGifts = ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/gifts"
      headers:
        Authorization: "Bearer #{userToken}"
    ).then (res) ->
      return res.data.results

  @claimSubscriptionGifts = (gifts) ->
    userToken = localStorage.getItem('access_token')
    gift_ids = gifts.map (gift) ->
      return gift.gift.id
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/claim_gifts"
      data:
        gift_ids: gift_ids
      headers:
        Authorization: "Bearer #{userToken}"
    ).then (res) ->
      return res.data

  @isClaimComplete = (giftIds) ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/claim_complete"
      data:
        gift_ids: giftIds
      headers:
        Authorization: "Bearer #{userToken}"
    ).then (res) ->
      return res.data.complete

  @waitForClaimComplete = (gifts, timeout = 30000, interval = 5000) =>
    giftIds = gifts.map (gift) ->
      return gift.gift.id

    return $q((resolve, reject) =>
      timeoutAfter = Date.now() + timeout

      poll = =>
        @isClaimComplete(giftIds)
        .then (isComplete) ->
          if (isComplete)
            return resolve(true)
          if (Date.now() > timeoutAfter)
            return reject(new Error("timeout"))
          $timeout (poll), interval
        .catch (error) ->
          return reject(error)

      poll()
    )

  @updatePaymentMethod = ->
    @chargebeeInstance.openCheckout(
      hostedPage: ->
        userToken = localStorage.getItem('access_token')
        return $http(
          method: 'POST'
          url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/update_payment_method_session"
          headers:
            Authorization: "Bearer #{userToken}"
        ).then (res) ->
          return res.data
      loaded: ->
        AnalyticsService.track 'Update Payment Method Loaded'
      step: (currentStep) ->
        AnalyticsService.track 'Update Payment Method Step', {currentStep: currentStep}
      close: ->
        AnalyticsService.track 'Update Payment Method Closed'
      success: (hostedPageId) ->
        AnalyticsService.track 'Update Payment Method Completed', {hostedPageId: hostedPageId}
      error: (error) ->
        AnalyticsService.track 'Update Payment Method Error', {error: error}
    )
        
  @switchSubscription = (planType, coupon_code) ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/switch_subscription"
      data:
        plan_type: planType
        coupon_code: coupon_code
      headers:
        Authorization: "Bearer #{userToken}"
    )

  @removeScheduleCancel = (offer) ->
    userToken = localStorage.getItem('access_token')
    $http(
      method: 'POST'
      data: {
        offer: offer
      }
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/remove_schedule_cancel"
      headers:
        Authorization: "Bearer #{userToken}"
    )

  @subscribePremium = (premiumCode) ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    $http(
      method: 'PUT'
      headers: headerParams
      url: "#{csConfig.chefstepsEndpoint}/api/v0/charges/#{premiumCode}/redeem"
    )

  @redeemCouponForNextSub = (couponCode) ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    $http(
      method: 'POST'
      headers: headerParams
      data: {
        coupon: couponCode
      }
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/redeem_coupon"
    )

  @redeemStudioPassTrial = (couponCode) ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    $http(
      method: 'PUT'
      headers: headerParams
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/#{couponCode}/redeem_trial"
    ).then (res) ->
      console.log(res)

  @validateCoupon = (couponCode) ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    $http(
      method: 'GET'
      headers: headerParams
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/#{couponCode}/validate_coupon"
    )

  @redeemAndExtendSubscription = (couponCode) ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    $http(
      method: 'PUT'
      headers: headerParams
      url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/#{couponCode}/redeem/extend_term"
    )


  @subscribeTrial = (couponCode, onError, onSuccess) =>
    AnalyticsService.track 'Subscription Checkout Loading', {couponCode: couponCode}
    @chargebeeInstance.openCheckout(
      hostedPage: ->
        userToken = localStorage.getItem('access_token')
        return $http(
          method: 'POST'
          url: "#{csConfig.chefstepsEndpoint}/api/v0/subscription/#{couponCode}/generate_trail_checkout"
          headers:
            Authorization: "Bearer #{userToken}"
        ).then (res) ->
          return res.data
      loaded: ->
        AnalyticsService.track 'Subscription with trail Checkout Loaded', {couponCode: couponCode}
      step: (currentStep) ->
        AnalyticsService.track 'Subscription with trail Checkout Step', {couponCode: couponCode, currentStep: currentStep}
      close: ->
        AnalyticsService.track 'Subscription with trail Checkout Closed', {couponCode: couponCode}
      success: (hostedPageId) =>
        AnalyticsService.track 'Subscription with trail Checkout Completed', {couponCode: couponCode, hostedPageId: hostedPageId}
        this.redeemStudioPassTrial(couponCode)
        return this.syncSubscriptions().then (_) ->
          $state.reload($state.current)
          onSuccess()
      error: (error) ->
        AnalyticsService.track 'Subscription with trail Checkout Error', {couponCode: couponCode, error: error}
        if error.status == 400
          onError(error['data']['message'])
        else
          onError(error)
    )

  @chargebeeInstance = Chargebee.getInstance()

  this
