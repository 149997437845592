@app.service 'ClassesService', ($http, $q, $state, $timeout, localStorageService) ->

  @search = (query) ->
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/classification/search?query=#{query}"
      headers:
        Authorization: "Bearer #{localStorage.getItem('access_token')}"
    )

  @find = (id, source) ->
    param = []
    if !_.isEmpty(source)
      param.push("source=#{source}")
    if !_.isEmpty(id)
      param.push("id=#{id}")
    param = param.join('&')
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/classification/activity?#{param}"
      headers:
        Authorization: "Bearer #{localStorage.getItem('access_token')}"
    )

  @findBySlug = (slug) ->
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/classification/#{slug}"
      headers:
        Authorization: "Bearer #{localStorage.getItem('access_token')}"
    )

  @getPrice = (planId) ->
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/classification/#{planId}/get_price"
    )

  this