@app.directive 'premiumSubscriptionButton', ($state, $location, AnalyticsService) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    label: '='
    buttonUrl: '='
    theme: '='
    alreadySubscribedTitle: '='
    alreadySubscribedText: '='
  }
  controllerAs: 'premiumSubscriptionButton'
  controller: ($scope, subscriptionService, ModalService, UserAccountService) ->
    codePattern = /// ^
      [-a-z0-9]{3,32}
      $ ///i

    @isPremium = $state.currentUser?.premium_member
    @code = $location.search().premium_code || $location.search().coupon_code
    @showSpinner = false
    @takeMeHome = ->
      window.location = @buttonUrl || "/premium"

    @redeem = (code) =>
      if !$state.currentUser
        # ModalService.open('login', source: 'getpremiumRedeem')
        UserAccountService.redirectToAuthPage()
      else
        @showSpinner = true
        subscriptionService.subscribePremium(code)
          .then (response) =>
            AnalyticsService.track 'Premium Code Redeemed'
            @error_message = null
            @showSpinner = false
            ModalService.open('buyResult', { type: "redeem", description: "ChefSteps Premium", buttonText: "Let's Get Started"})
              .then =>
                @takeMeHome()
              .catch =>
                @takeMeHome()
          .catch (error) =>
            @showSpinner = false
            AnalyticsService.track 'Premium Code Redeem Error', error: error
            err = _.get(error, 'data.error', _.get(error, 'statusText', "Something went wrong"))
            ModalService.open('buyResult', { type: "error", message: err})

    $scope.onClick = =>
      if !!@code && @code.match codePattern
        @error_message = null
        @redeem(@code)
      else
        @error_message = 'Please enter a valid code'

    this

  templateUrl: '/assets/templates/components/customButton/premiumSubscriptionButton/premiumSubscriptionButton.html'
