@app.service 'ModalService', ($rootScope, $q, $state, $http)->

  @modal = {
    deferred: null
    params: null
  }

  @open = (type, params) =>

    # If this modal is opening as a chain from a previous modal, like login->signup, hold
    # on to the old promise and resolve it when done.
    oldDeferred = @modal.deferred
    @modal.deferred = $q.defer()

    # Just resolve right away if the modal was login/signup and they already is that.
    if $state.currentUser && _.contains(['login', 'signup'], type)
      @modal.deferred.resolve()
    else
      @modal.params = params
      $rootScope.$emit 'modals.open', type, params

    @modal.deferred.promise.then oldDeferred?.resolve, oldDeferred?.reject

  @params = =>
    @modal.params || {}

  @reject = (reason) =>
    return if ! @modal.deferred
    @modal.deferred.reject reason
    @modal.deferred = null
    @modal.params = null
    $rootScope.$emit 'modals.close'

  @resolve = (response) =>
    return if ! @modal.deferred
    @modal.deferred.resolve response
    @modal.deferred = null
    @modal.params = null
    $rootScope.$emit 'modals.close'

  # Use this when the modal may have made a change that requires the state to re-resolve,
  # e.g. setting $state.currentUser.
  @reloadAndResolve = (response) =>
    $rootScope.$on '$stateChangeSuccess', =>
      @resolve(response)
    $state.reload()

  # This is a bit hacky, but better to share it here than repeat it
  @ftueIfFromNav = =>
    @reloadAndResolve()

  @shouldSendToFTUE = =>
    @modal.params?.source == 'nav'

  @resolveSuccess = (type='normal') =>
    # Switched away from the reloadAndResolve and instead do our own so that we can
    # show the special terms after login.  This is requested from the lawyers
    $rootScope.$on '$stateChangeSuccess', =>
      if $state.currentUser?.needs_special_terms
        userToken = localStorage.getItem('access_token')
        $http(
          method: 'GET'
          url: '/api/v0/users/shown_terms'
          headers:
            Authorization: "Bearer #{userToken}"
        )
        @open('specialTerms')
      else
        if type == 'ftue'
          @open('ftue')
        else if type == 'modalConsent'
          @open('modalConsent')
        else
          @resolve()
    $state.reload()

  this
