@app.directive 'madlib', (authenticationService, GtagServiceHelper, $rootScope, $state, csAdwords, csFacebookConversion, AnalyticsService, ModalService, formHelper, LocationService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'banner'
  controller: ($scope) ->
    LocationService.loadData()

    @userCount = $state.userCount || 984575
    @showPassword = false
    @showSuccess = false
    @showMadlib = false
    @showSpinner = false
    @showOptInConsent = _.contains(['doubleOptIn', 'singleOptIn'], LocationService.getConsentType())
    @password_pattern = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/

    if $state.currentUser
      @showMadlib = false
    else
      @showMadlib = false

    @togglePassword = ->
      AnalyticsService.track 'Madlib Toggled Password', {url: $state.current.url}
      @showPassword = !@showPassword

    @isInUS = ->
      LocationService.isInUS()

    @showErrorFor = (fieldName, validationParam) =>
      formHelper.showErrorFor(@bannerForm, fieldName, validationParam)

    @submit = =>

      registrationSuccess = =>
        AnalyticsService.track 'Madlib Registration Success', {url: $state.current.url}
        GtagServiceHelper.signup()
        csAdwords.track(998032928,'77TfCIjjrAgQoIzz2wM')
        csFacebookConversion.track(6014798030226,0.00)

        # Mixpanel
        properties = {source : @source, method: 'standard'}
        AnalyticsService.track 'Signed Up JS', properties

        # Google Analytics
        AnalyticsService.track 'Complete', { category: 'Sign Up' }

        @showSuccess = true
        @showMadlib = false
        @showSpinner = false

        #ModalService.open('ftue')
        $state.reload()

      registrationError = =>
        console.log @bannerForm
        AnalyticsService.track 'Madlib Registration Error', {url: $state.current.url}
        $rootScope.$emit 'New Alert', {type: 'error', message: 'An error occured while registering your account.'}
        @showSpinner = false

      if @bannerForm.$valid
        AnalyticsService.track 'Madlib Form Submitted', {url: $state.current.url}
        @showSpinner = true
        optIn = if @showOptInConsent then (@bannerForm.optin?.$modelValue || false) else true
        authenticationService
          .registerWithEmail @bannerForm.name.$modelValue, @bannerForm.email.$modelValue, @bannerForm.password.$modelValue, optIn
          .then registrationSuccess, registrationError

    @successClose = =>
      @showSuccess = false

    $scope.$watch 'banner.bannerForm.$dirty', (newValue, oldValue) ->
      if newValue
        AnalyticsService.track 'Madlib Form Dirty', {url: $state.current.url}

    $scope.$watch 'banner.bannerForm.$valid', (newValue, oldValue) ->
      if newValue
        AnalyticsService.track 'Madlib Submit Button Active', {url: $state.current.url}

    this


  bindToController: {
    theme: '='
    content: '='
    source: '='
  }
  templateUrl: "/assets/templates/components/banner/bannerMadlib/madlib.html"
