@app.directive 'ftue', ($state, $http, ModalService, localStorageService)->
  restrict: 'E'
  scope: {}
  controller: ($scope, $element) ->

    @showSurvey = false
    @disabledNext = true
    @suggestion = ''

    @interests = [
      {
        title: 'Sous Vide'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/VO5w9ZlJQzSuY39wVjCA'
        imageLoaded: false
      }
      {
        title: 'Kitchen Tips'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/zGFKehokRquV2wqanIj0'
        imageLoaded: false
      }
      {
        title: 'Traditional Cooking'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/xiojrYF1QAemQ0ybBTbl'
        imageLoaded: false
      }
      {
        title: 'Modern Cooking'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/eXkRnCTNSlKqLwIYYr4n'
        imageLoaded: false
      }
      {
        title:'Beverages'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/4kMxDcRFTGmaa55Ro7M5'
        imageLoaded: false
      }
      {
        title: 'Baking'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/ykypDm7TbnEga0m5D9AQ'
        imageLoaded: false
      }
      {
        title: 'Behind the Scenes'
        checked: false
        image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/dvc519Tk2h97xIdwj5hg'
        imageLoaded: false
      }
    ]

    @toggleNext = =>
      checks = @interests.map (option) -> option.checked
      if( _.some checks) || (@suggestion?.length > 0)
        @disabledNext = false
      else
        @disabledNext = true

    @toggleChecked = (interest) =>
      interest.checked = !interest.checked
      @toggleNext()

    @next = =>
      @scrollToTop()
      survey_results = {}
      survey_results.interests = []
      survey_results.suggestion = @suggestion
      @interests.map (interest) ->
        if interest.checked
          survey_results.interests.push interest.title
      data = { survey_results: survey_results }
      # Mixpanel analytics no longer used
      #mixpanel.track('Survey Answered', survey_results)
      userToken = localStorage.getItem('access_token')
      $http(
        method: 'POST'
        url: "#{csConfig.chefstepsEndpoint}/api/v0/user_surveys"
        data: data
        headers:
          Authorization: "Bearer #{userToken}"
      )

      searchTerms = survey_results.interests
      searchTerms.push survey_results.suggestion if survey_results.suggestion?.length > 0
      searchParams = {
        tags: searchTerms.join(',')
        per: 8
      }
      $http.get("#{csConfig.chefstepsEndpoint}/api/v0/recommendations", {params: searchParams}).then (response) =>
        @recommendations = response.data
        @showSurvey = false

        if @recommendations.length > 0
          @showRecommendations = true
        else if @recommendations.length == 0 && survey_results.suggestion
          @showSuggestionMessage = true

    @done = ->
      ModalService.resolve()

    @skip = ->
      ModalService.reject()

    @scrollToTop = ->
      el = angular.element $element
      el[0].scrollTop = 0

    this

  controllerAs: 'ftue'
  templateUrl: "/assets/templates/modals/ftue/ftue.html"
