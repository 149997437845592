@app.service 'CustomerOrderService', ($resource, csConfig, localStorageService, $q) ->

  userToken = localStorage.getItem('access_token')
  headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }

  @resource = $resource "#{csConfig.chefstepsEndpoint}/api/v0/shopping/customer_orders/:id", { id: '@id' },
    'updateAddress':
      url: "#{csConfig.chefstepsEndpoint}/api/v0/shopping/customer_orders/:id/update_address"
      method: 'POST'
      headers: headerParams
    'confirmAddress':
      url: "#{csConfig.chefstepsEndpoint}/api/v0/shopping/customer_orders/:id/confirm_address"
      method: 'POST'
      headers: headerParams
    'show':
      method: 'GET'
      isArray: false
      headers: headerParams

  @resource
