@app.directive 'saveButton', ->
  restrict: 'E'
  replace: true
  scope: {}
  bindToController:
    saveableType: '@'
    saveableId: '@'
    apiEndpoint: '@'
  controllerAs: 'saveButton'
  controller: ($state, $scope, AnalyticsService, ModalService, $http, localStorageService) ->
    @isSaved = false
    @buttonType = 'save'
    savedId = null

    getHeaderConfig = ->
      userToken = localStorage.getItem('access_token')
      {headers: { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }}

    updateIsSaved = =>
      return if (!@saveableId || !$state.currentUser)
      if @saveableType == 'Activity'
        url = "#{csConfig.chefstepsEndpoint}/api/v0/#{@apiEndpoint}/#{@saveableId}/likes_by_user"
      else
        url = "#{csConfig.chefstepsEndpoint}/api/v0/#{@apiEndpoint}/#{@saveableId}/likes"
      $http.get(url, getHeaderConfig()).then((res) =>
        @isSaved = _.chain(res.data).pluck('userId').includes($state.currentUser?.id).value()
        if @isSaved
          @buttonType = 'saved'
          savedId = _.find(res.data, {userId: $state.currentUser.id}).id
      )

    $scope.$watch (=> @saveableId), updateIsSaved

    save = =>
      if !$state.currentUser
        ModalService.open('login', source: 'saveButton')
        return
      url = "#{csConfig.chefstepsEndpoint}/api/v0/likes?likeable_type=#{@saveableType}&likeable_id=#{@saveableId}"
      data = {}
      $http.post(url, data, getHeaderConfig())
        .success((res)=>
          @isSaved = true
          @buttonType = 'saved'
          savedId = res.id
          AnalyticsService.track('Liked', {'Activity': @saveableType + "_" + @saveableId})
      )

    unsave = =>
      if !$state.currentUser
        ModalService.open('login', source: 'unsaveButton')
        return
      url = "#{csConfig.chefstepsEndpoint}/api/v0/likes/#{savedId}"
      data = {}
      $http.delete(url, getHeaderConfig())
        .success((res)=>
          @isSaved = false
          @buttonType = 'save'
          AnalyticsService.track('Unliked', {'Activity': @saveableType + "_" + @saveableId})
      )

    @toggleSaved = => if @isSaved then unsave() else save()

    this

  templateUrl: "/assets/templates/directives/saveButton/saveButton.html"
