# Factory that maps to api v0 components endpoint
@app.factory 'PageService', ($resource, csConfig, localStorageService) ->
  userToken = localStorage.getItem('access_token')
  $resource "#{csConfig.chefstepsEndpoint}/api/v0/pages/:id", { id: '@id' },
    'create':
      method: 'POST'
    'index':
      method: 'GET'
      isArray: true
      headers: { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    'show':
      method: 'GET'
      isArray: false
    'update':
      method: 'PUT'
      headers: { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
    'destroy':
      method: 'DELETE'
