@app.service 'navService', ($rootScope, $http, localStorageService) ->

  # Nav States:
  # nav-ghost
  # nav-sticky
  # nav-joule

  @previousState
  @navState = ''

  @setState = (navState) =>
    @navState = navState
    $rootScope.$emit 'nav.stateChange'

  @setPreviousState = =>
    @previousState = @navState

  @usePreviousState = =>
    @navState = @previousState

  @getState = =>
    @navState

  @getMenus = ->
    userToken = localStorage.getItem('access_token')
    headerParams = { 'Authorization': 'Bearer ' + userToken }
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/menus/list"
      headers: headerParams
    ).then (res) ->
      return res.data

  @showCollectionNavPrompt = true

  this
