@app.directive 'internationalJoule', (ModalService, utilities, $http, localStorageService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()

    @close = ->
      ModalService.resolve()

    @encodeForMailto = (text) ->
      utilities.urlSafeString(text)

    @continueToCheckout = =>
      if @params.checkoutUrl
        # For Shopify
        window.location.assign @params.checkoutUrl

    @addToInternationalJouleMailingList = ->
      ModalService.open('login', source: 'buyButton').then =>
        userToken = localStorage.getItem('access_token')
        headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }

        $http(
          method: 'POST'
          headers: headerParams
          params: null
          url: '/api/v0/users/international_joule'
        ).success((data, status, headers, config) =>
          @params.type = 'thanks'
        ).error((data, status, headers, config) =>
          @params.type = 'error'
        )

    this

  controllerAs: 'internationalJoule'
  templateUrl: "/assets/templates/modals/internationalJoule/internationalJoule.html"
