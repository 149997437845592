@app.directive 'hero', (csFilepickerMethods, $window, AnalyticsService, utilities, $rootScope, $timeout, $state)->
  restrict: 'E'
  scope: {}
  controllerAs: 'hero'
  controller: ($element, $scope) ->
    @getAspect = ->
      return 'portrait' if ($element.children()[0].clientHeight / 9) > ($element.children()[0].clientWidth / 16)
      return 'landscape'

    @isVideoVisible = false

    @applyGreyScale = !(_.isEmpty(@content.title) && _.isEmpty(@content.description))

    @mediaContent = _.omit(@content, ["description"])
    @canSave = =>
      ($state.currentUser?.premium || $state.currentUser?.studio || ($state.currentUser?.purchased_classes?.length > 0)) && !_.isEmpty(@classDetail.plan)

    @showVideo = =>
      @isVideoVisible = true
      $rootScope.$broadcast 'nav.hideNav', @isVideoVisible

    @hideVideo = =>
      @isVideoVisible = false
      $rootScope.$broadcast 'nav.hideNav', @isVideoVisible

    @handleEmbedVideoClicked = =>
      @hideVideo() if _.isEmpty(@content.brightcoveVideoId)

    # AnalyticsService.track 'Component Shown', {type: 'hero', title: @content.title, url: @content.url}

    @track = ->
      AnalyticsService.track 'Component Item Clicked', {type: 'hero', title: @content.title, url: @content.url}

    # Needed only for AB test
    @heroImage = =>
      csFilepickerMethods.convert @content.image, {width: $window.outerWidth}

    @heightTypeClass = =>
      "hero-height-#{@content.heightType}"

    @heroTypeClass = =>
      "hero-type-#{@content.heroType || 'standard'}"

    @alignTypeClass = =>
      "hero-align-#{@content.alignType}"

    @content.description = utilities.customSanitize(@content.description)
    @content.buttonHide ||= false

    $scope.$watch 'hero.content', (newValue, oldValue) =>
      if newValue && @autoplay
        @hideVideo() if @isVideoVisible
        $timeout =>
          @showVideo()

    this

  link: (scope, element, attrs) ->

    if scope.hero.content && scope.hero.content.url && !scope.hero.content.buttonMessage
      element.on 'mouseenter', ->
        element.css('cursor', 'pointer')

      element.on 'click', ->
        $window.location.href = scope.hero.content.customButton.url

  bindToController: {
    theme: '='
    content: '='
    autoplay: '='
    enableHeader: '='
    classDetail: '='
  }
  templateUrl: "/assets/templates/components/hero/hero.html"
