@app.service 'VideoService', ($http, localStorageService) ->

  @getBrightcovePlayersList = ->
    userToken = localStorageService.get('user.token')
    headerParams = {'Authorization': 'Bearer ' + userToken}
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/brightcove/players_list"
      headers: headerParams
    )
  this
